<template>
  <section class="m-check-phone m-scroll">

    <!-- 头部导航 -->
    <m-header title="验证邮箱">
      <span slot="right" @click="onNext">下一步</span>
    </m-header>

    <p class="title">请完成以下身份认证</p>


    <div class="input-cell">
      <input v-model="email" type="text" readonly>
    </div>

    <div class="input-cell">
      <input v-model="code" type="text" placeholder="请输入验证码">
      <div class="get-code">
        <span v-show="!getCode" @click="onSend" class="get">获取验证码</span>
        <span v-show="getCode"  class="seconds">{{seconds}}S</span>
      </div>
    </div>
  
    <div class="footer">
      <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">下一步</van-button>
    </div>


    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

let timer = null 

export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      email:'416994573@qq.com',
      code:'',
      getCode:false,
      seconds:60,
    }
  },

  

  methods:{

    onSend(){
      this.$notify({ type: 'success', message: '发送成功' });
      this.getCode = true
      timer = setInterval(() => {
        if(this.seconds < 2){
          this.getCode = false
          this.seconds = 60
          clearInterval(timer)
        }
        this.seconds -- 
      }, 1000); 
    },
    onNext(){
      this.$router.push( {path: '/zh/setting/email'} )
    },


   
  },
  destroyed(){
    timer && clearInterval(timer)
  }
}
</script>

<style lang="less" scoped>
.m-check-phone {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .title {
    margin: 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-left: 20px;
  }
  .input-cell {
    background: #fff;
    padding:0 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 52px;
    >input {
      outline: none;
      border: none;
      font-size: 14px;
    }
    .get-code {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        padding: 5px 8px;
        border: 1px solid #3284FA;
        color: #3284FA;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
      }
    }
  }
  .footer {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
  }
  .for-email {
    margin-top: 30px;
    text-align: center;
    font-size:14px;
    font-weight:400;
    color:rgba(51,51,51,1);
    text-decoration: underline;
  }
}
</style>